exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-avtorskiye-prava-js": () => import("./../../../src/pages/avtorskiye-prava.js" /* webpackChunkName: "component---src-pages-avtorskiye-prava-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-o-nas-js": () => import("./../../../src/pages/o-nas.js" /* webpackChunkName: "component---src-pages-o-nas-js" */),
  "component---src-pages-shagi-js": () => import("./../../../src/pages/shagi.js" /* webpackChunkName: "component---src-pages-shagi-js" */),
  "component---src-pages-temi-js": () => import("./../../../src/pages/temi.js" /* webpackChunkName: "component---src-pages-temi-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */)
}

